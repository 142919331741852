export const GrowthMarketing = {
    partner: [
        {
        "orgName": "Bal Harbour Village",
        "link": "https://www.balharbourfl.gov/",
        "name":"Gabriel Groisman",
        "email":" mayor@balharbourfl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
      {
        "orgName": "Branches",
        "link": "https://branchesfl.org/",
        "name":"Karla Bachmann",
        "email":" Kbachmann@branchesfl.org",
        "phone": "",
        "comments":"",
        "keywords":["e-commerces", "Branding", "Distribution", "Growth", "Marketing"]
      },
        {
        "orgName": "City of Aventura",
        "link": "http://www.cityofaventura.com",
        "name":"Eric Soroka",
        "email":" esoroka@cityofaventura.com",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Coral Gables",
        "link": "http://www.coralgables.com",
        "name":"Michael Mena",
        "email":" mmena@coralgables.com",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Doral",
        "link": "http://www.cityofdoral.com",
        "name":"Juan Carlos Bermudez",
        "email":" juancarlos.bermudez@cityofdoral.com",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Hialeah",
        "link": "https://www.hialeahfl.gov/",
        "name":"Carlos Hernandez",
        "email":" mayorchernandez@hialeahfl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Hialeah Gardens",
        "link": "http://www.cityofhialeahgardens.org",
        "name":"Yioset De La Cruz",
        "email":" ydelacruz@cityofhialeahgardens.com",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Homestead",
        "link": "http://www.ci.homestead.fl.us",
        "name":"Stephen Shelley",
        "email":" sshelley@cityofhomestead.com",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Miami",
        "link": "https://www.miamigov.com/Home",
        "name":"Francis Suarez",
        "email":" fsuarez@miamigov.com",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Miami Beach",
        "link": "http://www.ci.miami-beach.fl.us",
        "name":"Dan Gelber",
        "email":" dan@gelberformayor.com",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Miami Gardens",
        "link": "http://www.miamigardens-fl.gov",
        "name":"Oliver Gilbert III",
        "email":" ogilbert@miamigardens-fl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Miami Springs",
        "link": "https://www.miamisprings-fl.gov/",
        "name":"Billy Bain",
        "email":" bainb@miamisprings-fl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of North Miami",
        "link": "http://www.northmiamifl.gov",
        "name":"Philippe Bien-Aime",
        "email":" pbien-aime@northmiamifl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of North Miami Beach",
        "link": "https://www.citynmb.com/",
        "name":"Anthony DeFillipo",
        "email":" anthony.defillipo@citynmb.com",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Opa-Locka",
        "link": "www.opalockafl.gov",
        "name":"Matthew Pigatt",
        "email":" mpigatt@opalockafl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of South Miami",
        "link": "https://www.southmiamifl.gov/",
        "name":"Philip Stoddard",
        "email":" pstoddard@southmiamifl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Sunny Isles Beach",
        "link": "https://www.sibfl.net/",
        "name":"George Scholl",
        "email":" gscholl@sibfl.net",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of Sweetwater",
        "link": "https://cityofsweetwater.fl.gov/",
        "name":"Jose Diaz",
        "email":" jdiaz@cityofsweetwater.fl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "City of West Miami",
        "link": "https://cityofwestmiamifl.com/",
        "name":"Rhonda Rodriguez",
        "email":" rhondaarodriguez@cityofwestmiami.org",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Florida City",
        "link": "http://www.floridacityfl.gov/",
        "name":"Otis Wallace",
        "email":" mayor@floridacityfl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Indian Creek village",
        "link": "https://indiancreekvillage.org/",
        "name":"Bernard Klepach",
        "email":" village@icvps.org",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Miami-Dade County",
        "link": "http://www.miamidade.gov",
        "name":"",
        "email":"",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Miami Shores Village",
        "link": "https://www.miamishoresvillage.com/",
        "name":"Crystal Wagar",
        "email":" crystalwagar@msvfl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "North Bay Village",
        "link": "https://northbayvillage-fl.gov/",
        "name":"Brent Latham",
        "email":" blatham@nbvillage.com",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Overtown Connect - powered by Venture Cafe",
        "link": "https://floridajobs.org/community-planning-and-development/Small-and-Minority-Business-Resources/Small-and-Minority-Business-Programs/microfinance-guarantee-program",
        "name":"Meissa Pierre Kelly",
        "email":"Overtown@venturecafemiami.org",
        "phone": "",
        "comments":"",
        "keywords":["e-commerce", "Branding", "Distribution", "Black", "Minority", "Innovation", "Entrepreneur", "Training", "Networking", "Access to Capital"]
      },
      {
        "orgName": "Prospera",
        "link": "https://prosperausa.org/",
        "name":"Myrna Sonora",
        "email":"msonora@prosperausa.org",
        "phone": "",
        "comments":"",
        "keywords":["e-commerce", "Branding", "Distribution", "Hispanic Small Business", "Training", "Consulting", "Grants", "Access to Capital", "Spanish", "International", "Immigration"]
      },
        {
        "orgName": "Town of Bay Harbor Islands",
        "link": "https://www.bayharborislands-fl.gov/",
        "name":"Stephanie Bruder",
        "email":" sbruder@bayharborislands-fl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Town of Cutler Bay",
        "link": "http://www.cutlerbay-fl.gov/",
        "name":"Edward MacDougall",
        "email":" pvrooman@cutlerbay-fl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Town of Golden Beach",
        "link": "http://www.goldenbeach.us/",
        "name":"Glen Singer",
        "email":" gsinger@goldenbeach.us",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Town of Medley",
        "link": "http://www.townofmedley.com/",
        "name":"Robert Martell",
        "email":" rmartell@townofmedley.com",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Town of Miami Lakes",
        "link": "https://www.miamilakes-fl.gov/",
        "name":"Michael Pizzi, Jr.",
        "email":" pizzim@miamilakes-fl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Town of Surfside",
        "link": "https://www.townofsurfsidefl.gov/",
        "name":"Daniel Dietch",
        "email":" ddietch@townofsurfsidefl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Village of Biscayne Park",
        "link": "https://www.biscayneparkfl.gov/",
        "name":"Tracy Truppman",
        "email":" ttruppman@biscayneparkfl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Village of El Portal",
        "link": "http://elportalvillage.com/",
        "name":"Claudia Cubillos",
        "email":" mayorcubillos@villageofelportal.org",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Village of Key Biscayne",
        "link": "https://www.biscayneparkfl.gov/",
        "name":"Michael Davey",
        "email":" mdavey@keybiscayne.fl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Village of Palmetto Bay",
        "link": "https://www.palmettobay-fl.gov/",
        "name":"Karyn Cunningham",
        "email":" kcunningham@palmettobay-fl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "Village of Virginia Gardens",
        "link": "http://www.virginiagardens-fl.gov",
        "name":"Spencer Deno IV",
        "email":" mayor@virginiagardens-fl.gov",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
        {
        "orgName": "WeWork Labs Miami",
        "link": "https://www.weworklabs.com/labs/",
        "name":"Pedro Sostre",
        "email":" pedro.sostre@wework.com",
        "phone": "",
        "comments":"",
        "keywords":["Lenders", "Grants", "Financial Assistance", "Growth", "Marketing", "Legal Services", "Incentives", "Business Development"]
      },
      {
        "orgName": "SBDC at FIU- Small Business Development Center at FIU",
        "link": "https://business.fiu.edu/centers/sbdc/",
        "name":"Shelly Bernal",
        "email":"Shelly.bernal@floridasbdc.org",
        "phone": "",
        "comments":"",
        "keywords":["e-commerce", "Branding", "Distribution", "Disaster Capital", "Recovery", "Rebuild", "Growth"]
      },
        {
        "orgName": "SCORE Miami Dade",
        "link": "https://miamidade.score.org/",
        "name":"",
        "email":"score.bizhelp.miami@scorevolunteer.org",
        "phone": "",
        "comments":"",
        "keywords":["e-commerce", "Branding", "Distribution", "Recovery", "Rebuild", "Growth"]
      },
      {
        "orgName": "Miami Bayside Foundation",
        "link": "https://miamibaysidefoundation.org/",
        "name": "Joann Milord",
        "email": "joann@mbf.miami",
        "phone": "",
        "comments":"",
        "keywords": ["Haitian Creole Speaking", "Spanish Speaking", "Micro Loans", "Loan Packaging", "Growth Marketing", "Procurement"]
      },
     
    ],
  }
  
  export default GrowthMarketing
  
